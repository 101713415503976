<template>
  <div class="wellspaDetail">
    <img :src="data.url + 'wellspa/bg.png'" alt="" class="bg" />
    <div class="logo">
      <img :src="data.url + 'wellspa/title_1.png'" alt="" />
    </div>
    <div class="title">为您呈现 WellSpa iO<br />产品介绍与产品美图</div>
    <div class="areaBox">
      <div class="areaTitle">产品介绍</div>
      <div class="areaContent">
        <div class="leftPart">
          <div class="coveImg">
            <img :src="data.url + 'wellspa/setting.png'" alt="" />
          </div>
          <div class="partTitle">产品介绍</div>
        </div>
        <div class="rightPart">
          <div class="partRow" @click="openIntroduce('in1')">
            即刻启动循环 >
          </div>

          <div class="partRow" @click="openIntroduce('in2')">
            美丽健康 一手掌握 >
          </div>
          <div class="partRow" @click="openIntroduce('in5')">
            Vera+M-Power 智能物联 >
          </div>
          <div class="partRow" @click="openIntroduce('in3')">使用手册 ></div>
          <div class="partRow" @click="openIntroduce('in4')">常见问答 ></div>
        </div>
      </div>
      <div class="areaContent">
        <div class="leftPart">
          <div class="coveImg">
            <img :src="data.url + 'wellspa/produce.png'" alt="" />
          </div>
          <div class="partTitle">产品操作</div>
        </div>
        <div class="rightPart">
          <div class="partRow" @click="openSetting('set1')">简单操作指南 ></div>
          <div class="partRow" @click="openSetting('set2')">蓝牙重置 ></div>
          <div class="partRow" @click="openSetting('set3')">恢复出厂设置 ></div>
          <div class="partRow" @click="openSetting('set4')">Vera操作指南 ></div>
        </div>
      </div>
    </div>
    <div class="areaBox">
      <div class="areaTitle">产品美图</div>
      <div class="row">
        <div class="rowItem" @click="openProduct">
          <div class="productImg">
            <img :src="data.url + 'wellspa/product1.png'" alt="" />
          </div>
          <div class="productTitle">
            产品纯图
            <img :src="data.url + 'right_black.png'" alt="" />
          </div>
        </div>
        <div class="rowItem" @click="openModel">
          <div class="productImg">
            <img :src="data.url + 'wellspa/product2.png'" alt="" />
          </div>
          <div class="productTitle">
            产品模特图
            <img :src="data.url + 'right_black.png'" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="empty"></div>
    <div class="backBtn" @click="backIndex">
      <img :src="data.url + 'wellspa/backBtn.png'" alt="" />
    </div>
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";
import { reactive, onMounted } from "vue";
import { prviewVideoBox } from "../../js/until";
import { baiduTotal } from "../../js/common.js";
let data = reactive({
  url: "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/", //图片地址
  introduceObj: {
    in1: "https://nudrive.cn.nuskin.com/file/1hwn0hleuyehhtgm13t5l41e5nthnkl8#",
    in2: "https://nudrive.cn.nuskin.com/file/39sztw9s8tioc68ki07kctb7gf2p6tda#",
    in3: "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/wellspa/shiyongshouce.pdf",
    in4: "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/wellspa/wenda.pdf",
    in5: "https://nudrive.cn.nuskin.com/file/zt6p7ihot1exsmyun5ffxq6pdfw70yk2#",
  },
  settingObj: {
    set1: "https://nudrive.cn.nuskin.com/file/o5yahfbvgxdanfh011a0bk41ujp6zkec#",
    set2: "https://nudrive.cn.nuskin.com/file/0lmxfbpereousdb01i3vjrto7e1zyrwu#",
    set3: "https://nudrive.cn.nuskin.com/file/ml3d8hmafn51v445xaziac8yfihly4xt#",
    set4: "https://nudrive.cn.nuskin.com/file/suas0dpmavaeq95yob0u0b0pgpog98i0#",
  },
});
const router = useRouter();
//查看产品介绍
let openIntroduce = (key) => {
  if (key == "in1") {
    prviewVideoBox({
      videoUrl:
        "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/wellspa/jkqdxh.mp4",
      qcodeUrl: "",
      poster: "",
      wangpanPath: data.introduceObj[key],
    });
  } else if (key == "in2") {
    prviewVideoBox({
      videoUrl:
        "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/wellspa/mljk.mp4",
      qcodeUrl: "",
      poster: "",
      wangpanPath: data.introduceObj[key],
    });
  } else if (key == "in5") {
    prviewVideoBox({
      videoUrl:
        " https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/wellspa/wulian.mp4",
      qcodeUrl:
        "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/wellspa/wulianqr.png",
      poster: "",
      wangpanPath: data.introduceObj[key],
    });
  } else {
    window.location.href = data.introduceObj[key];
  }
};
//返回首页
let backIndex = () => {
  router.replace("/index");
};
//打开产品
let openProduct = () => {
  window.location.href = `https://mp.weixin.qq.com/s/EcAgMrZcf536VkKpEHgcYg`;
};
//查看设置
let openSetting = (key) => {
  let videoUrl = "";
  if (key == "set1") {
    videoUrl =
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/wellspa/guide.mp4";
  } else if (key == "set2") {
    videoUrl =
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/wellspa/Bluetooth.mp4";
  } else if (key == "set3") {
    videoUrl =
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/wellspa/restoreSettings.mp4";
  } else if (key == "set4") {
    videoUrl =
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/wellspa/operateGuide.mp4";
  }
  prviewVideoBox({
    videoUrl: videoUrl,
    qcodeUrl: "",
    poster: "",
    wangpanPath: data.settingObj[key],
  });
};
//打开模特
let openModel = () => {
  window.location.href = `https://mp.weixin.qq.com/s/D3R__WovXP0YtrzEmVherw`;
};
onMounted(() => {
  baiduTotal();
});
</script>
<style lang="scss" scoped>
.wellspaDetail {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: relative;
  padding-top: 0.48rem;
  background: #e6e7e9;
  .bg {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .logo {
    width: 100%;
    overflow: hidden;
    position: relative;
    z-index: 10;
    font-size: 0;
    display: flex;
    justify-content: center;
    img {
      max-width: 2.22rem;
    }
  }
  .title {
    width: 100%;
    text-align: center;
    font-size: 0.16rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #7e8da1;
    line-height: 0.18rem;
    margin: 0.25rem 0 0.4rem;
    position: relative;
    z-index: 2;
  }
  .video {
    position: relative;
    width: 100%;
    height: 2.1rem;
    z-index: 1;
  }
  .remark {
    position: relative;
    z-index: 1;
    color: #7e8da1;
    text-align: center;
    font-family: PingFang SC;
    font-size: 0.12rem;
    font-weight: 400;
    margin: 0.12rem 0 0.16rem;
  }
  .downLoadBtn {
    margin-top: 0.16rem;
    border-color: #7e8da1;
    color: #7e8da1;
  }
  .areaBox {
    position: relative;
    width: 3.5rem;
    border-radius: 0.1rem;
    background: linear-gradient(
      90deg,
      #7e8da1 0%,
      #b2bac5 50.14%,
      #7e8da1 100%
    );
    padding: 0.12rem 0.06rem 0.1rem;
    box-sizing: border-box;
    z-index: 1;
    margin: 0.28rem auto;
    .areaTitle {
      color: #fff;
      text-align: center;
      text-shadow: 0 0.02rem 0.02rem #7e8da1;
      font-family: "Source Han Serif";
      font-size: 0.26rem;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 4.42px;
      margin-bottom: 0.1rem;
    }
    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .rowItem {
        width: 1.64rem;
        background: linear-gradient(
          90deg,
          #cdd0d7 0%,
          #fff 49.85%,
          #cdd0d7 100%
        );
        box-shadow: 0 0.02rem 0.1rem 0 rgba(9, 198, 185, 0.1);
        border-radius: 0.1rem;
        overflow: hidden;
        .productImg {
          width: 100%;
          font-size: 0;
          img {
            width: 100%;
          }
        }
        .productTitle {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #000;
          font-family: "PingFang SC";
          font-size: 0.12rem;
          font-weight: 400;
          line-height: 0.15rem;
          margin-top: 0.1rem;
          padding: 0 0.12rem 0.1rem 0.26rem;
          box-sizing: border-box;
          img {
            height: 0.24rem;
          }
        }
      }
    }
    .areaContent {
      width: 100%;
      background: linear-gradient(90deg, #cdd0d7 0%, #ffffff 50%, #cdd0d7 100%);
      border-radius: 0.1rem;
      display: flex;
      flex-direction: row;
      margin-bottom: 0.06rem;
      &:last-child {
        margin-bottom: 0;
      }
      .leftPart {
        width: 1.17rem;
        border-right: 1px solid #d3d1d0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .coveImg {
          height: 0.6rem;
          font-size: 0;
          margin-bottom: 0.16rem;
          img {
            height: 100%;
          }
        }
        .partTitle {
          font-family: Source Han Serif CN, Source Han Serif CN;
          font-weight: bold;
          font-size: 0.19rem;
          color: #7e8da1;
          line-height: 1;
          letter-spacing: 0.03rem;
          text-shadow: inset 2px 2px 2px rgba(72, 98, 132, 0.4);
        }
      }
      .rightPart {
        width: 0;
        flex: 1;
        .partRow {
          width: 100%;
          height: 0.44rem;
          border-bottom: 1px solid #d3d1d0;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #000000;
          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }
}
</style>
